a {
  text-decoration: none;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.nh:hover {
  background-color: inherit;
}

.css-xt7mm5-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #64067F !important
}

.container {
  max-width: 1080px;
  display: box;
  margin: auto;
  padding: 24px 16px;
}

#global-search-bar {
  color: white !important;
}
#global-search-bar-template {
  color: #212121 !important;
}

.MuiAppBar-root {
  box-shadow: 0px 1px 0px black !important;
}