@media only screen and (max-width: 600px) {
    .perspective-info .view-doc {
        display: none;
    }

    .perspective-info .view-info {
        flex-basis: 100%;
        max-width: 100%;
    }

    .perspective-info .show-info {
        display: none;
    }

    .perspective-doc .view-doc {
        flex-basis: 100%;
        max-width: 100%;
    }

    .perspective-doc .view-info {
        display: none;
    }

    .perspective-doc .show-doc {
        display: none;
    }

    .perspective-trigger{
        display: block;
    }
}

@media only screen and (min-width: 600px) {
    /**
    * Hide the button when the screen width is higher than 600px
    */
    .perspective-button-container{
        display: none;
    }
}

.scale-transform-12 {
    transform: scale(1.2) !important ;
}


.scale-transform {
    transition: all 0.5s ease-in;
    transform: scale(1);
}

