.contract-card {
    border: 1px solid rgb(200, 200, 200);
    border-radius: 8px;
    margin-bottom: 18px;
    padding: 4px 8px 16px 24px;
}

.contract-card-error {
    border: 1px solid #D32F2F;
    border-radius: 8px;
    margin-bottom: 18px;
    padding: 4px 8px 16px 24px;
}

.contract-card-warning {
    border: 1px solid #ffa726;
    border-radius: 8px;
    margin-bottom: 18px;
    padding: 4px 8px 16px 24px;
}

.contract-card-urgent {
    border: 1px solid #f57c00;
    border-radius: 8px;
    margin-bottom: 18px;
    padding: 4px 8px 16px 24px;
}

.contract-card.inactive {
    /* border : 1px solid rgb(220, 60, 60); */
}

.contract-card.nrtbi {
    /* border : 1px solid black; */
}

.contract-card.rtbi {
    /* border : 1px solid rgb(220, 60, 220); */
}

.contract-card.signed {
    /* border : 1px solid rgb(60, 220, 60); */
}

.contract-card.wfs {
    /* border : 1px solid rgb(60, 60, 220); */
}